import React, { useState } from 'react';
import PromptForm from './PromptForm';
import confetti from 'canvas-confetti';

const defaultPrompts = [
  // Work Category
  { id: 1, category: 'work', title: 'Arrange meeting', description: 'Schedule a meeting with {person} on {date} at {time} to discuss {topic}.', variables: ['person', 'date', 'time', 'topic'] },
  { id: 2, category: 'work', title: 'Being late for work', description: 'Apologize for being late to work due to {reason}. Estimated arrival time is {time}.', variables: ['reason', 'time'] },
  { id: 3, category: 'work', title: 'Request time off', description: 'Request time off from {start_date} to {end_date} for {reason}.', variables: ['start_date', 'end_date', 'reason'] },
  { id: 4, category: 'work', title: 'Project status update', description: 'Provide a status update on the {project_name} project, highlighting {achievement} and mentioning {challenge}.', variables: ['project_name', 'achievement', 'challenge'] },
  { id: 5, category: 'work', title: 'Introduce new team member', description: 'Introduce {new_member} to the team, mentioning their role as {role} and their key skill in {skill}.', variables: ['new_member', 'role', 'skill'] },
  { id: 6, category: 'work', title: 'Request feedback', description: 'Request feedback on {project_or_task}, specifically asking about {aspect1} and {aspect2}.', variables: ['project_or_task', 'aspect1', 'aspect2'] },
  { id: 7, category: 'work', title: 'Proposal for new initiative', description: 'Propose a new initiative called {initiative_name} that aims to {goal}. Highlight the benefit of {key_benefit}.', variables: ['initiative_name', 'goal', 'key_benefit'] },
  { id: 31, category: 'work', title: 'Cover Letter', description: 'Apply for the position of {job_title} at {company}. Highlight skills: {skill1} and {skill2}.', variables: ['job_title', 'company', 'skill1', 'skill2'] },
  { id: 32, category: 'work', title: 'Resignation letter', description: 'Formally resign from the position of {job_title} at {company}, effective {date}. Express gratitude for {positive_aspect}.', variables: ['job_title', 'company', 'date', 'positive_aspect'] },
  { id: 56, category: 'work', title: 'Remote work request', description: 'Request to work remotely {frequency} due to {reason}. Outline plan to maintain {productivity_measure}.', variables: ['frequency', 'reason', 'productivity_measure'] },
  { id: 57, category: 'work', title: 'Conflict resolution', description: 'Address a conflict with {colleague} regarding {issue}. Propose {solution} as a way forward.', variables: ['colleague', 'issue', 'solution'] },

  // Sales Category
  { id: 8, category: 'sales', title: 'Closing e-mail', description: 'Follow up on our discussion about {product/service}. Offer a {discount}% discount if they sign up by {deadline}.', variables: ['product/service', 'discount', 'deadline'] },
  { id: 9, category: 'sales', title: 'Cold outreach', description: 'Reach out to {prospect} about our {product/service}, highlighting its ability to {key_benefit}.', variables: ['prospect', 'product/service', 'key_benefit'] },
  { id: 10, category: 'sales', title: 'Thank you after meeting', description: 'Thank {client} for the meeting on {date}. Summarize key points discussed: {point1} and {point2}.', variables: ['client', 'date', 'point1', 'point2'] },
  { id: 11, category: 'sales', title: 'Product demo follow-up', description: 'Follow up after demonstrating {product} to {client}. Address their concern about {concern} and reaffirm the benefit of {benefit}.', variables: ['product', 'client', 'concern', 'benefit'] },
  { id: 12, category: 'sales', title: 'Proposal submission', description: 'Submit a proposal for {project} to {client}, emphasizing our expertise in {skill} and our unique approach to {challenge}.', variables: ['project', 'client', 'skill', 'challenge'] },
  { id: 13, category: 'sales', title: 'Reconnect with past client', description: 'Reconnect with {past_client} about our new {product/service} that addresses their previous need for {past_need}.', variables: ['past_client', 'product/service', 'past_need'] },
  { id: 33, category: 'sales', title: 'Request for referral', description: 'Ask {client} if they would be willing to refer us to other businesses that might benefit from our {product/service}.', variables: ['client', 'product/service'] },
  { id: 58, category: 'sales', title: 'Upsell opportunity', description: 'Propose an upgrade to {client} from {current_plan} to {higher_plan}, highlighting new features: {feature1} and {feature2}.', variables: ['client', 'current_plan', 'higher_plan', 'feature1', 'feature2'] },
  { id: 59, category: 'sales', title: 'Lost deal follow-up', description: 'Follow up with {prospect} who chose a competitor. Express understanding and offer to {value_proposition} if their needs change.', variables: ['prospect', 'value_proposition'] },

  // Marketing Category
  { id: 14, category: 'marketing', title: 'Company updates', description: 'Share company news about {topic}. Highlight key achievements: {achievement1} and {achievement2}.', variables: ['topic', 'achievement1', 'achievement2'] },
  { id: 15, category: 'marketing', title: 'Product launch announcement', description: 'Announce the launch of {product_name}, highlighting its {key_feature} and how it solves {problem}.', variables: ['product_name', 'key_feature', 'problem'] },
  { id: 16, category: 'marketing', title: 'Event invitation', description: 'Invite subscribers to {event_name} on {date}. Emphasize the opportunity to learn about {topic} and network with {industry} professionals.', variables: ['event_name', 'date', 'topic', 'industry'] },
  { id: 17, category: 'marketing', title: 'Customer success story', description: 'Share how {client} achieved {result} using our {product/service}, overcoming {challenge} in the process.', variables: ['client', 'result', 'product/service', 'challenge'] },
  { id: 18, category: 'marketing', title: 'Newsletter content', description: 'Craft a newsletter featuring {main_topic}, including sections on {subtopic1} and {subtopic2}.', variables: ['main_topic', 'subtopic1', 'subtopic2'] },
  { id: 19, category: 'marketing', title: 'Social media post', description: 'Create a social media post about {topic}, using the hashtags #{hashtag1} and #{hashtag2}.', variables: ['topic', 'hashtag1', 'hashtag2'] },
  { id: 34, category: 'marketing', title: 'Press release', description: 'Announce {company}\'s {achievement} in the {industry} industry, quoting {spokesperson} about its significance.', variables: ['company', 'achievement', 'industry', 'spokesperson'] },
  { id: 60, category: 'marketing', title: 'Webinar promotion', description: 'Promote upcoming webinar on {webinar_topic}, featuring expert {speaker_name}. Highlight key takeaway: {main_benefit}.', variables: ['webinar_topic', 'speaker_name', 'main_benefit'] },
  { id: 61, category: 'marketing', title: 'Holiday campaign', description: 'Create a {holiday} themed campaign for {product}, emphasizing {seasonal_benefit} and offering {special_offer}.', variables: ['holiday', 'product', 'seasonal_benefit', 'special_offer'] },

  // Customer Service Category
  { id: 20, category: 'customer service', title: 'Respond to complaint', description: 'Respond to a complaint about {issue}, apologize for the inconvenience, and offer {solution} as resolution.', variables: ['issue', 'solution'] },
  { id: 21, category: 'customer service', title: 'Product return instructions', description: 'Provide instructions for returning {product}, including the return code {code} and the deadline of {date}.', variables: ['product', 'code', 'date'] },
  { id: 22, category: 'customer service', title: 'Subscription renewal reminder', description: 'Remind {customer} that their subscription for {service} is due for renewal on {date}. Highlight new feature {new_feature}.', variables: ['customer', 'service', 'date', 'new_feature'] },
  { id: 23, category: 'customer service', title: 'Feedback request', description: 'Request feedback from {customer} about their recent experience with our {service}, focusing on {aspect1} and {aspect2}.', variables: ['customer', 'service', 'aspect1', 'aspect2'] },
  { id: 35, category: 'customer service', title: 'Service outage notification', description: 'Notify customers about a service outage affecting {service} from {start_time} to {end_time}. Explain that it\'s due to {reason}.', variables: ['service', 'start_time', 'end_time', 'reason'] },
  { id: 62, category: 'customer service', title: 'Escalation response', description: 'Respond to an escalated issue regarding {problem}. Apologize for the experience, explain {root_cause}, and offer {compensation}.', variables: ['problem', 'root_cause', 'compensation'] },
  { id: 63, category: 'customer service', title: 'Proactive service notification', description: 'Inform {customer} about a potential issue with {product/service} and provide {preventive_steps} to avoid problems.', variables: ['customer', 'product/service', 'preventive_steps'] },

  // HR Category
  { id: 24, category: 'hr', title: 'Job offer', description: 'Extend a job offer to {candidate} for the position of {job_title}, starting on {start_date} with a salary of {salary}.', variables: ['candidate', 'job_title', 'start_date', 'salary'] },
  { id: 25, category: 'hr', title: 'Interview invitation', description: 'Invite {candidate} for an interview for the {job_title} position on {date} at {time}. The interviewer will be {interviewer}.', variables: ['candidate', 'job_title', 'date', 'time', 'interviewer'] },
  { id: 26, category: 'hr', title: 'Employee recognition', description: 'Recognize {employee} for their outstanding work on {project}, specifically their contribution to {achievement}.', variables: ['employee', 'project', 'achievement'] },
  { id: 27, category: 'hr', title: 'Policy update announcement', description: 'Announce updates to the {policy_name} policy, highlighting changes in {aspect1} and {aspect2}.', variables: ['policy_name', 'aspect1', 'aspect2'] },
  { id: 64, category: 'hr', title: 'Performance improvement plan', description: 'Outline a performance improvement plan for {employee}, focusing on {area_of_improvement} with a goal to achieve {target} by {deadline}.', variables: ['employee', 'area_of_improvement', 'target', 'deadline'] },
  { id: 65, category: 'hr', title: 'Team building event', description: 'Invite team to a {event_type} on {date}. The event will focus on {team_goal} and include activities like {activity1} and {activity2}.', variables: ['event_type', 'date', 'team_goal', 'activity1', 'activity2'] },

  // Personal Category
  { id: 28, category: 'personal', title: 'Wedding invitation', description: 'Invite {guest} to the wedding of {partner1} and {partner2} on {date} at {venue}.', variables: ['guest', 'partner1', 'partner2', 'date', 'venue'] },
  { id: 29, category: 'personal', title: 'Thank you note', description: 'Thank {person} for the {gift} they gave for {occasion}. Mention how you plan to {use_gift}.', variables: ['person', 'gift', 'occasion', 'use_gift'] },
  { id: 30, category: 'personal', title: 'Apology message', description: 'Apologize to {person} for {mistake}. Express regret and suggest {make_amends} to make it right.', variables: ['person', 'mistake', 'make_amends'] },
  { id: 66, category: 'personal', title: 'Birthday wishes', description: 'Wish {person} a happy birthday, reminiscing about {shared_memory} and expressing hope for {future_wish}.', variables: ['person', 'shared_memory', 'future_wish'] },
  { id: 67, category: 'personal', title: 'Condolence message', description: 'Express condolences to {person} for the loss of their {relation}. Share a {positive_memory} about the deceased if appropriate.', variables: ['person', 'relation', 'positive_memory'] },
  { id: 70, category: 'personal', title: 'Holiday greetings', description: 'Send holiday greetings to {recipient}, mentioning {shared_memory} and wishing them {specific_wish} for the coming year.', variables: ['recipient', 'shared_memory', 'specific_wish'] },
  { id: 71, category: 'personal', title: 'Reunion invitation', description: 'Invite {group_name} to a reunion on {date} at {location}, reminiscing about {past_event} and promising {activity}.', variables: ['group_name', 'date', 'location', 'past_event', 'activity'] },
  { id: 72, category: 'personal', title: 'House party invitation', description: 'Invite friends to a {party_type} party at your new home on {date}, mentioning {special_occasion} and requesting {what_to_bring}.', variables: ['party_type', 'date', 'special_occasion', 'what_to_bring'] },
  { id: 73, category: 'personal', title: 'Congratulations message', description: 'Congratulate {person} on their {achievement}, expressing {specific_praise} and wishing them {future_success}.', variables: ['person', 'achievement', 'specific_praise', 'future_success'] },
  { id: 74, category: 'personal', title: 'Moving announcement', description: 'Announce your move to {new_location}, sharing excitement about {new_opportunity} and inviting friends to {housewarming_plan}.', variables: ['new_location', 'new_opportunity', 'housewarming_plan'] },
  { id: 75, category: 'personal', title: 'Baby announcement', description: 'Announce the arrival of {baby_name} born on {date}, sharing {special_detail} and inviting friends to {celebration_plan}.', variables: ['baby_name', 'date', 'special_detail', 'celebration_plan'] },
  { id: 76, category: 'personal', title: 'Get-well message', description: 'Send wishes to {person} recovering from {condition}, offering {help_offered} and sharing {uplifting_message}.', variables: ['person', 'condition', 'help_offered', 'uplifting_message'] },
  { id: 77, category: 'personal', title: 'Graduation celebration', description: 'Celebrate {graduate}\'s graduation from {institution}, highlighting {accomplishment} and looking forward to {future_plans}.', variables: ['graduate', 'institution', 'accomplishment', 'future_plans'] },
  { id: 78, category: 'personal', title: 'Pet sitting request', description: 'Ask {friend} to watch {pet_name} during your trip to {destination}, providing {care_instructions} and offering {compensation}.', variables: ['friend', 'pet_name', 'destination', 'care_instructions', 'compensation'] },
  { id: 79, category: 'personal', title: 'Anniversary wishes', description: 'Celebrate {couple}\'s {number} anniversary, remembering {memory} and wishing them {future_blessing}.', variables: ['couple', 'number', 'memory', 'future_blessing'] },

  // Influencer Category
  { id: 50, category: 'influencer', title: 'Brand collaboration pitch', description: 'Pitch a collaboration idea to {brand_name} for their {product_line}, highlighting your expertise in {niche} and your {follower_count} engaged followers.', variables: ['brand_name', 'product_line', 'niche', 'follower_count'] },
  { id: 51, category: 'influencer', title: 'PR team outreach', description: 'Reach out to the PR team of {company_name} to express interest in their upcoming {campaign_name} campaign, showcasing your previous successful collaborations in the {industry} industry.', variables: ['company_name', 'campaign_name', 'industry'] },
  { id: 52, category: 'influencer', title: 'Event invitation response', description: 'Respond to an invitation from {event_organizer} for their {event_name}, confirming your attendance and inquiring about {specific_detail} of the event.', variables: ['event_organizer', 'event_name', 'specific_detail'] },
  { id: 53, category: 'influencer', title: 'Sponsorship proposal', description: 'Propose a sponsorship deal to {brand_name} for your upcoming {content_series}, detailing how it aligns with their {target_audience} and your plan to showcase their {product_or_service}.', variables: ['brand_name', 'content_series', 'target_audience', 'product_or_service'] },
  { id: 54, category: 'influencer', title: 'Collaboration follow-up', description: 'Follow up with {contact_name} from {company_name} regarding the {project_name} collaboration, sharing the positive feedback received and proposing next steps for {future_opportunity}.', variables: ['contact_name', 'company_name', 'project_name', 'future_opportunity'] },
  { id: 55, category: 'influencer', title: 'Media kit request', description: 'Request an updated media kit from your agent {agent_name}, specifying the need to highlight your recent {achievement} and updated statistics for your {platform} account.', variables: ['agent_name', 'achievement', 'platform'] },
  { id: 68, category: 'influencer', title: 'Affiliate program application', description: 'Apply to join the affiliate program of {company_name}, highlighting your success in promoting similar {product_category} and your strategy to {promotion_strategy}.', variables: ['company_name', 'product_category', 'promotion_strategy'] },
  { id: 69, category: 'influencer', title: 'Giveaway announcement', description: 'Announce a giveaway of {product_name} in partnership with {brand_name}. Specify the {entry_requirements} and {prize_details}.', variables: ['product_name', 'brand_name', 'entry_requirements', 'prize_details'] },
  { id: 80, category: 'influencer', title: 'Podcast guest pitch', description: 'Pitch yourself as a guest for {podcast_name}, highlighting your expertise in {topic} and sharing your {success_story}.', variables: ['podcast_name', 'topic', 'success_story'] },
  { id: 81, category: 'influencer', title: 'Event speaking proposal', description: 'Propose speaking at {event_name} about {topic}, sharing your {credentials} and potential {value_add} for attendees.', variables: ['event_name', 'topic', 'credentials', 'value_add'] },
  { id: 82, category: 'influencer', title: 'Collaboration cancellation', description: 'Professionally cancel collaboration with {brand} due to {reason}, maintaining relationship by suggesting {alternative_opportunity}.', variables: ['brand', 'reason', 'alternative_opportunity'] },
  { id: 83, category: 'influencer', title: 'Content usage rights', description: 'Request permission from {creator} to feature their {content_type} in your {project}, offering {compensation_terms}.', variables: ['creator', 'content_type', 'project', 'compensation_terms'] },
  { id: 84, category: 'influencer', title: 'Sponsorship renewal', description: 'Propose renewal of sponsorship with {brand} for another {duration}, highlighting {achievement} and suggesting {new_ideas}.', variables: ['brand', 'duration', 'achievement', 'new_ideas'] },
  { id: 85, category: 'influencer', title: 'Cross-promotion proposal', description: 'Propose cross-promotion with {influencer} for {campaign}, outlining {mutual_benefits} and {execution_plan}.', variables: ['influencer', 'campaign', 'mutual_benefits', 'execution_plan'] },
  { id: 86, category: 'influencer', title: 'Rate sheet update', description: 'Inform {agency} about updated rates for {content_type}, explaining {value_proposition} and {market_comparison}.', variables: ['agency', 'content_type', 'value_proposition', 'market_comparison'] },
  { id: 87, category: 'influencer', title: 'Platform verification request', description: 'Request verification on {platform}, highlighting your {achievements} and {public_presence} in the {industry}.', variables: ['platform', 'achievements', 'public_presence', 'industry'] },
  { id: 88, category: 'influencer', title: 'Brand exclusivity agreement', description: 'Negotiate exclusivity with {brand} in the {category} space for {duration}, outlining {terms} and {deliverables}.', variables: ['brand', 'category', 'duration', 'terms', 'deliverables'] },
  { id: 89, category: 'influencer', title: 'Content calendar proposal', description: 'Present content calendar to {client} for {quarter}, featuring {themes} and {engagement_strategies}.', variables: ['client', 'quarter', 'themes', 'engagement_strategies'] }
];

const Prompts = ({ setSelectedPrompt }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(null);

  const categories = ['All', ...new Set(defaultPrompts.map(prompt => prompt.category.charAt(0).toUpperCase() + prompt.category.slice(1)))];

  const filteredPrompts = defaultPrompts.filter(prompt =>
    (activeCategory === 'All' || prompt.category.toLowerCase() === activeCategory.toLowerCase()) &&
    (prompt.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     prompt.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handlePromptClick = (prompt) => {
    setCurrentPrompt(prompt);
    setShowPromptForm(true);
    fireConfetti();
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-pink-100 to-purple-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-indigo-800 mb-6">Popular Prompts</h2>
        <input
          type="text"
          placeholder="Search prompts..."
          className="w-full p-3 mb-6 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="flex flex-wrap gap-2 mb-6 overflow-x-auto pb-2">
          {categories.map(category => (
            <button
              key={category}
              className={`px-4 py-2 rounded-md transition-colors whitespace-nowrap ${
                activeCategory === category
                  ? 'bg-indigo-600 text-white'
                  : 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
              }`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="space-y-4">
          {filteredPrompts.map(prompt => (
            <div
              key={prompt.id}
              className="bg-white rounded-lg border border-indigo-200 overflow-hidden cursor-pointer hover:border-indigo-400 transition-colors"
              onClick={() => handlePromptClick(prompt)}
            >
              <div className="p-4 sm:p-6">
                <span className="inline-block px-2 py-1 text-xs font-semibold text-indigo-700 bg-indigo-100 rounded-full mb-2">
                  {prompt.category.charAt(0).toUpperCase() + prompt.category.slice(1)}
                </span>
                <h3 className="text-lg font-semibold text-indigo-900 mb-2">{prompt.title}</h3>
                <p className="text-sm text-indigo-600 mb-4">{prompt.description}</p>
                <button className="text-sm font-medium text-indigo-700 hover:text-indigo-900 transition-colors duration-300">
                  Use Prompt →
                </button>
              </div>
            </div>
          ))}
        </div>
        {showPromptForm && currentPrompt && (
          <PromptForm
            prompt={currentPrompt}
            onClose={() => setShowPromptForm(false)}
            setSelectedPrompt={setSelectedPrompt}
          />
        )}
      </div>
    </div>
  );
};

export default Prompts;
